import styled from 'styled-components';
import { device } from '../../../theme';

export const RelatedPostsWrap = styled.div`
  margin-top: 24px;
  margin-bottom: 2px;
  ${device.medium} {
    margin-bottom: 0;
  }
`;
